import React from "react";

interface Props {
	children: React.ReactNode;
	display?: boolean;
	as?: string;
	className?: string;
}

export const headlineSize = "text-2xl md:text-3xl";

const H1: React.FC<Props> = ({ children, display = false, as = "h1", className }) => {
	const classes = `font-bold font-serif text-primary-800 ${display ? "text-4xl md:text-5xl" : headlineSize} ${
		className ? className : ""
	}`;
	return React.createElement(as, { className: classes }, children);
};

export default H1;
