// Packages
import React from "react";
import { graphql, Link } from "gatsby";
import {
	PresentationChartBarIcon,
	ChatIcon,
	InformationCircleIcon,
	UserIcon,
	BookOpenIcon,
	MailIcon,
} from "@heroicons/react/outline";

// Components
import Container from "~components/common/Container";
import Layout from "~components/layouts/Layout";
import H1 from "~components/typography/H1";
import P from "~components/typography/P";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	return (
		<Layout
			location={location}
			title="Anfrage gesendet"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			noIndex
		>
			<Container>
				<div className="mb-10 text-center">
					<P className="mb-2" textColor="text-primary-600">
						Anfrage gesendet
					</P>
					<H1 display className="mb-4">
						Wir melden uns bei dir zeitnah.
					</H1>
				</div>
				<div className="mx-auto max-w-md">
					<P className="mb-4">Weiter geht es hier:</P>
					<ul className="mt-4 divide-y divide-gray-200 border-t border-b border-gray-200 ">
						{[
							{
								name: "Workshops",
								to: "/workshops/",
								icon: PresentationChartBarIcon,
							},
							{
								name: "Speaker",
								to: "/speaker/",
								icon: InformationCircleIcon,
							},
							{
								name: "Coaching",
								to: "/coaching/",
								icon: ChatIcon,
							},
							{ name: "Profil", to: "/profil/", icon: UserIcon },
							// {
							// 	name: "Blog",
							// 	to: "/blog/",
							// 	icon: BookOpenIcon,
							// },
							{
								name: "Kontakt",
								to: "/kontakt/",
								icon: MailIcon,
							},
						].map((link) => (
							<li className="relative flex items-start space-x-4 py-6" key={link.name}>
								<link.icon className="h-8 w-8 flex-shrink-0 text-primary" aria-hidden="true" />
								<div className="min-w-0 flex-1">
									<h3 className="text-base font-medium text-gray-900">
										<span className="rounded-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2">
											<Link to={link.to} className="focus:outline-none">
												<span className="absolute inset-0" aria-hidden="true" />
												{link.name}
											</Link>
										</span>
									</h3>
								</div>
								<div className="flex-shrink-0 self-center">
									<svg
										className="h-5 w-5 text-gray-400"
										x-description="Heroicon name: solid/chevron-right"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
									>
										<path
											fillRule="evenodd"
											d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
							</li>
						))}
					</ul>

					<div className="mt-8">
						<Link to="/" className="text-base font-medium text-primary-600 hover:text-primary-500">
							Oder gehe zur Startseite<span aria-hidden="true"> →</span>
						</Link>
					</div>
				</div>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	{
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
